<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <HeaderFilter></HeaderFilter>
    <BannerSection location="homepage-top"></BannerSection>
    <section class="tourism-container">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <div class="search-bar">
          <p class="search-label">{{ $t('TOURISM.FILTER') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
        <div class="d-flex content-filter-btns flex-wrap">
          <button class="primary-btn">
            {{ $t("TOURISM.RECOMMENDED_TRADE_SHOWS") }}
          </button>
          <router-link :to="{ name: 'TradeShowsUpcoming' }" class="primary-btn">
            {{ $t("TOURISM.UPCOMING_TRADE_SHOWS") }}
          </router-link> 
          <div class="d-flex align-items-center m-5">
            <SortTourism @sortDeals="sortDeals" :isTourismDeal="true"></SortTourism>
            <FilterTourism></FilterTourism>
          </div> 
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <router-link :to="{ name: 'TradeShowsCategory' }">
            <div class="adver-item d-flex">
              <img src="/assets/img/tour-places/church.png" alt="" class="w-100 h-100">
              <div class="adver-txt">
                Test1
              </div>
            </div>
          </router-link>  
        </div>
        <div class="col-sm-3">
          <div class="adver-item d-flex">
            <img src="/assets/img/tour-places/museum.png" alt="" class="w-100 h-100">
            <div class="adver-txt">
              Test2
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="adver-item d-flex">
            <img src="/assets/img/tour-places/market.png" alt="" class="w-100 h-100">
            <div class="adver-txt">
              Test3
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="adver-item d-flex">
            <img src="/assets/img/tour-places/church.png" alt="" class="w-100 h-100">
            <div class="adver-txt">
              {{ $t("TOURISM.ADVERTISEMENT") }}
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="adver-item d-flex">
            <img src="/assets/img/tour-places/church.png" alt="" class="w-100 h-100">
            <div class="adver-txt">
              {{ $t("TOURISM.ADVERTISEMENT") }}
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="adver-item d-flex">
            <img src="/assets/img/tour-places/church.png" alt="" class="w-100 h-100">
            <div class="adver-txt">
              {{ $t("TOURISM.ADVERTISEMENT") }}
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="adver-item d-flex">
            <img src="/assets/img/tour-places/church.png" alt="" class="w-100 h-100">
            <div class="adver-txt">
              {{ $t("TOURISM.ADVERTISEMENT") }}
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="adver-item d-flex">
            <img src="/assets/img/tour-places/church.png" alt="" class="w-100 h-100">
            <div class="adver-txt">
              {{ $t("TOURISM.ADVERTISEMENT") }}
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="adver-item d-flex">
            <img src="/assets/img/tour-places/church.png" alt="" class="w-100 h-100">
            <div class="adver-txt">
              {{ $t("TOURISM.ADVERTISEMENT") }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import HeaderFilter from "../../../components/tourism/tradeShow/HeaderFilter.vue" 
import BannerSection from "../../../components/BannerSection" 
import FilterTourism from "../../../components/tourism/FilterTourism";
import SortTourism from "../../../components/tourism/SortTourism";

export default {
  name: "TradeShows",
  components: {
    HeaderFilter,
    BannerSection,
    FilterTourism,
    SortTourism
  },    
  data() {
    return {
      search: ""
    }
  },
  methods: {
    sortDeals() {

    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";
.search-bar {
  min-width: 300px;
}
.content-filter-btns {
  .primary-btn {
    background-color: #002036;
    height: 30px;
    font-size: 16px;
    border-radius: 3px;
    margin: 5px;
    white-space: nowrap;
    padding: 0 5px;
    width: auto;
  }
}

.adver-item {
  position: relative;
  .adver-txt {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px;
  }
}
</style>